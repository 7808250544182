<template>
  <div class="auth-wrapper auth-v2">
    <b-row class="auth-inner m-0 align-items-center justify-content-center">
      <!-- Forgot Password -->
      <b-col
        lg="5"
        md="8"
        sm="12"
        class="d-flex align-items-center auth-bg px-2 p-lg-5 mx-2"
      >
        <b-col
          sm="8"
          md="10"
          lg="12"
          class="px-xl-2 mx-auto"
        >
          <b-card-title
            title-tag="h1"
            class="font-weight-bold mb-1 text-center"
          >
            Forgot Password
          </b-card-title>

          <!-- form -->
          <validation-observer ref="loginValidation">
            <b-form
              class="auth-login-form mt-2"
              @submit.prevent
            >
              <!-- email -->
              <b-form-group
                label="Email"
                label-for="login-email"
              >
                <validation-provider
                  #default="{ errors }"
                  name="Email"
                  rules="required|email"
                >
                  <b-form-input
                    id="login-email"
                    v-model="models.email"
                    :state="errors.length > 0 ? false:null"
                    name="login-email"
                    placeholder="Masukkan email yang sudah terdaftar"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>

              <!-- submit buttons -->
              <b-button
                type="submit"
                variant="primary"
                block
                @click="validationForm"
              >
                Submit
              </b-button>
            </b-form>
          </validation-observer>

          <b-card-text class="text-center mt-2">
            <b-link :to="{name:'login'}">
              <span>&nbsp;Kembali</span>
            </b-link>
          </b-card-text>
        </b-col>
      </b-col>
    <!-- /Login-->
    </b-row>
  </div>
</template>

<script>
import api from '@/utils/api'
/* eslint-disable global-require */
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import {
  BRow, BCol, BLink, BFormGroup, BFormInput, BCardText, BCardTitle, BForm, BButton,
} from 'bootstrap-vue'
import { required, email } from '@validations'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

export default {
  metaInfo: {
    title: 'Forgot Password',
  },
  components: {
    BRow,
    BCol,
    BLink,
    BFormGroup,
    BFormInput,
    BCardText,
    BCardTitle,
    BForm,
    BButton,
    ValidationProvider,
    ValidationObserver,
  },
  data() {
    return {
      models: {
        email: '',
        isAdmin: true,
      },
      // validation rulesimport store from '@/store/index'
      required,
      email,
    }
  },
  methods: {
    validationForm() {
      this.$refs.loginValidation.validate().then(success => {
        if (success) {
          this.$axios.post(api.forgotPassword, this.models)
            .then(() => {
              this.$toast({
                component: ToastificationContent,
                props: {
                  title: 'Form Submitted',
                  text: 'Please check email to reset password instructions.',
                  icon: 'CheckCircleIcon',
                  variant: 'success',
                },
              })
              this.$router.push('/login')
            })
            .catch(error => {
              this.$toast({
                component: ToastificationContent,
                props: {
                  title: error.response.data.message,
                  icon: 'XIcon',
                  variant: 'danger',
                },
              })
            })
        }
      })
    },
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/pages/page-auth.scss';
</style>
